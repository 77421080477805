<template>
  <div class="flight__container">
    <div class="flight__details">
      <div class="flight__departure">
        <div class="flight__company">
          <div class="flight__image-wrapper">
            <img class="flight__image" src="/assets/img/easy-jet.png" alt />
          </div>
          <p class="text">{{ flight.to.airline }}</p>
        </div>

        <div class="flight__day">
          <b class="text flight__day-value">{{
            flight.to.date | moment('D/M')
          }}</b>
          <p class="text">{{ $t(tWeek(flight.to.date)) }}</p>
        </div>

        <div class="flight__departure-details">
          <b class="flight__departure-time">{{ flight.to.depTime }}</b>
          <p class="text flight__departure-airport">
            {{ flight.to.departureIATA }}
          </p>
          <span class="flight__span flight__dayofweek">{{
            $t(tWeek(flight.to.date))
          }}</span>
        </div>

        <div class="flight__arrival-details">
          <b class="flight__arrival-time">{{ flight.to.arrTime }}</b>
          <p class="text flight__arrival-airport">
            {{ flight.to.arrivalIATA }}
          </p>
          <span class="flight__span flight__dayofweek">{{
            $t(
              arrWeek(
                flight.to.date,
                flight.to.depTime,
                flight.to.arrTime,
              ),
            )
          }}</span>
        </div>
      </div>

      <div class="flight__arrival">
        <div class="flight__company">
          <div class="flight__image-wrapper">
            <img class="flight__image" src="/assets/img/easy-jet.png" alt />
          </div>
          <p class="text">{{ flight.from.airline }}</p>
        </div>

        <div class="flight__day">
          <b class="text flight__day-value">{{
            flight.from.date | moment('D/M')
          }}</b>
          <p class="text">{{ $t(tWeek(flight.from.date)) }}</p>
        </div>

        <div class="flight__departure-details">
          <b class="flight__departure-time">{{
            flight.from.depTime
          }}</b>
          <p class="text flight__departure-airport">
            {{ flight.from.departureIATA }}
          </p>
          <span class="flight__span flight__dayofweek">{{
            $t(tWeek(flight.from.date))
          }}</span>
        </div>

        <div class="flight__arrival-details">
          <b class="flight__arrival-time">{{ flight.from.arrTime }}</b>
          <p class="text flight__arrival-airport">
            {{ flight.from.arrivalIATA }}
          </p>
          <span class="flight__span flight__dayofweek">{{
            $t(
              arrWeek(
                flight.from.date,
                flight.from.depTime,
                flight.from.arrTime,
              ),
            )
          }}</span>
        </div>
      </div>
    </div>

    <div class="flight__including">
      <h3 class="title title__h3 flight__title-h3">
        {{ $t('TOURISM_PRODUCT.WHAT_INCLUDE') }}
      </h3>
      <div class="flight__including-wrapper">
        <div class="flight__food" v-if="flight.Meal != ''">
          {{ $t('COMMON.' + flight.Meal.toUpperCase()) }}
        </div>
        <div class="flight__carryon">
          <span class="flight__carryon-value"
            >{{ flight.MaxWeightHandbag }}
          </span>
          <span class="flight__span">{{ $t('COMMON.KG') }}</span>
        </div>
        <div class="flight__luggage">
          <span class="flight__luggage-value"
            >{{ flight.MaxWeight }}
          </span>
          <span class="flight__span">{{ $t('COMMON.KG') }}</span>
        </div>
        <div class="flight__plane">&mdash;</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightContainer',
  props: {
    flight: Object
  },
  methods: {
    /* calculate day of week */
    tWeek(wDay) {
      return "DATE."+ this.$moment(wDay).format('ddd').toUpperCase();
    },
    arrWeek(depDate, depTime, arrTime) {
      let arrDate = depDate;
      if(depTime > arrTime) {
        arrDate = this.$moment(depDate).add(1, 'days').format('YYYY-MM-DD');
      }
      return "DATE."+ this.$moment(arrDate).format('ddd').toUpperCase();
    },
  }
};
</script>

<style lang="scss" scoped>
.flight__image-wrapper {
  width: 40px;
  height: 40px;
}
.flight__company {
  margin-right: 0;
  margin-left: 30px;
}
.flight__day {
  margin-right: 0;
  margin-left: 40px;
}
.flight__dayofweek {
  margin-left: 0;
}
.flight__departure-airport {
  left: unset;
}
.flight__arrival-details::before {
  left: unset;
  right: -100px;
  @media screen and (max-width: 1365px) {
    right: -200px;
  }
  @media screen and (max-width: 767px) {
    right: -50px;
  }
}
.flight__arrival-airport {
  left: unset;
}
.flight__food::before {
  left: unset;
}
.flight__carryon::before {
  left: unset;
}
.flight__luggage::before {
  left: unset;
}
.flight__plane::before {
  left: unset;
}
.flight__details {
  padding: 18px 20px 10px;
}
.flight__including {
  padding: 5px 20px 10px;
  .flight__including-part {
    display: flex;
  }
  .flight__title-h3 {
    margin-bottom: 13px;
  }
}
.ltr-type {
  .flight-option {
    cursor: pointer;
    &:hover {
      .flight__details,
      .flight__including{
        box-shadow: 0 3px 6px #a4a4a4;
      }
    } 
    &.active-flight {
      .flight__details,
      .flight__including{
        box-shadow: 0 3px 6px #8a8a8a;
      }
    }
  }
  .flight__company {
    margin-right: 20px;
    margin-left: 0;
  }
  .flight__day {
    margin-right: 40px;
    margin-left: 0;
  }
  .flight__dayofweek {
    margin-right: 0;
    margin-left: 7px;
  }
  .flight__departure-airport {
    left: 0;
    right: unset;
  }
  .flight__arrival-details::before {
    left: -100px;
    right: unset;
    @media screen and (max-width: 1365px) {
      left: -200px;
    }
    @media screen and (max-width: 767px) {
      left: -50px;
    }
  }
  .flight__arrival-airport {
    right: unset;
    left: 0;
  }
  .flight__food::before {
    left: 3px;
    right: unset;
  }
  .flight__carryon::before {
    left: 5px;
    right: unset;
  }
  .flight__luggage::before {
    left: 3px;
    right: unset;
  }
  .flight__plane::before {
    left: 5px;
    right: unset;
  }
}
@media screen and (max-width: 767px) {
  .flight__departure {
    justify-content: space-between !important;
  }
}
</style>